import { callApiWithCancel } from "./apiWithCancel";

export const getListTypeOfDish = (data) =>
  callApiWithCancel(
    ` query($date_create_from: String, $date_create_to: String){
            getListTypeOfDish(
              date_create_to:$date_create_to,
              date_create_from:$date_create_from
          ){
                items {
                    dish_name
                    count
                }
            }
    }`,
    data
  );
