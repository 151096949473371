import { Spin, Modal, Row } from "antd";
import React, { useState, useEffect, useRef, useContext } from "react";
import Header from "../../components/Header";
import Order from "../../components/Oders";

import "./index.scss";

import { renderImageTitle } from "./render";

import {
  apiSubmitCookingOrder,
  apiGetListOrder,
  apiCookingItem,
  apiBomOrder,
  apiGetListOrderRefund,
  apiPushSocketIdToServer,
  apiSubmitCompleteOrder,
  apiKitchenCancelItem,
} from "../../apis/Order";

import { customNotification } from "src/utils/CommomFunction";
import moment from "moment";
import { STATUS_COLUMNS } from "./columns";
import { Droppable, DragDropContext } from "react-beautiful-dnd";
import { firebaseMessaging } from "src/firebaseInit";
import sound from "../../assets/notification.mp3";
import soundNotResponse from "../../assets/ordernotresponse.wav";
import { apiGetNotificationList } from "../../apis/Notification";
import NotificationComponent from "components/Notification";
import { statusConvertData } from "../../utils/CommomFunction";
import _, { isEqual } from "lodash";
import { useIdleTimer } from "react-idle-timer";
import { isJsonValid } from "../../utils/Validation/index";
import { wait } from "../../utils/CommomFunction/index";
import io from "socket.io-client";
import { useTranslation } from "react-i18next";
import OrderDetail from "components/OrderDetail";
import { getStoreInfo } from "apis/Store";
import { getListTypeOfDish } from "apis/getType";
import { getValueOfTypeByLabel } from "utils/getType";
import Container from "layouts/Container";
import { ThemeContext } from "handlers/ThemeContext";
import ButtonSelectMode from "./components/button";
import OrderV2 from "components/Oders-V2";

const { info } = Modal;

const HomePage = (props) => {
  const [orderList, setOrderList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [detailOrder, setDetailOrder] = useState();
  const [loadingButton, setLoadingButton] = useState(false);

  const [refundOrderList, setRefundOrderList] = useState([]);
  const currentModalCancel = useRef();
  const currentModalOrder = useRef("");
  const loopPlayAudio = useRef();
  const loopPlayAudioNotResponse = useRef();
  const playAudio = useRef();
  const [reload, set_reload] = useState(false);

  const [isLoadingApp, setIsLoadingApp] = useState(false);

  const [turnOffAppSound, setTurnOffAppSound] = React.useState(2);
  const [orderListNotResponse, setOrderListNotResponse] = useState([]);

  const [dataOrderModal, setDataOrderModal] = useState();
  const [isShowModalPending, setShowModalPending] = useState(false);
  const [isShowModalRecieved, setShowModalRecieved] = useState(false);
  const [isShowModalReadyShip, setShowModalReadyShip] = useState(false);
  const [isShowModalShipping, setShowModalShipping] = useState(false);
  const [isShowModalCancel, setShowModalCancel] = useState(false);
  const [isShowModalCooking, setShowModalCooking] = useState(false);
  const [isShowModalRejectOrder, setShowModalRejectOrder] = useState(false);
  const [isShowModalCookingPickUp, setIsShowModalCookingPickUp] =
    useState(false);
  const [timeReloadOrder, setTimeReloadOrder] = useState(null);

  const [isRequestNoti, setRequestNoti] = useState(false);

  const [isShowOrderDetail, setShowOrderDetail] = useState(false);
  const [orderDetail, setOrderDetail] = useState(null);
  const [typeList, setTypeList] = useState([]);
  const [typeSelect, setTypeSelect] = useState([]);
  const refOrderList = React.useRef([]);
  const intervalTitle = React.useRef();
  const reloadOrderRef = React.useRef();

  const bc = new BroadcastChannel("jollibeeBroadcast");
  const { t } = useTranslation();
  const checkNotificationPermission = () => {
    if (Notification.permission !== "granted") {
      Notification.requestPermission().then(function (permission) {
        if (
          permission &&
          typeof permission == "string" &&
          permission == "denied"
        ) {
          if (!isRequestNoti) {
            info({
              icon: <></>,
              title: (
                <span
                  style={{ fontWeight: "bold", fontSize: 24, color: "red" }}
                >
                  {t("alertNotifyPermission")}
                </span>
              ),
              content: (
                <p style={{ fontWeight: "500", fontSize: 16 }}>
                  {t("alertNotifyPermission2")}
                </p>
              ),
              onOk: () => {
                setRequestNoti(false);
              },
            });
            setRequestNoti(true);
          }
        }
      });
    }
  };

  useEffect(() => {
    checkNotificationPermission();
  }, []);

  const onIdle = () => {
    getOrderList();
    checkNotificationPermission();
    idleTimer.reset();
  };

  const idleTimer = useIdleTimer({
    onIdle,
    onActive: () => {},
    timeout: 1000 * 60 * 9.5,
  });

  const saveOrderListNotResponse = ({ order, type }) => {
    if (type == "add") {
      const index = orderListNotResponse.findIndex(
        (obj) => obj?.id == order?.id
      );
      if (index < 0) {
        const tempList = [...orderListNotResponse, order];
        setOrderListNotResponse(tempList);
      }
    } else if (type == "remove") {
      const tempList = orderListNotResponse.filter(
        (obj) => obj?.id !== order?.id
      );
      if (!isEqual(tempList, orderListNotResponse)) {
        setOrderListNotResponse(tempList);
      }
    }
  };

  const playOrderNOtResponseAgain = ({ order }) => {
    const indexOrder = orderListNotResponse.findIndex(
      (obj) => obj?.id == order?.id
    );
    if (indexOrder !== -1) {
      const tempList = [...orderListNotResponse];
      tempList[indexOrder] = {
        ...orderListNotResponse[indexOrder],
        isOffSound: false,
        timePause: null,
      };
      setOrderListNotResponse(tempList);
    }
  };

  const orginalTitle = document.title;

  const stopIntervalTitle = () => {
    clearInterval(intervalTitle.current);
    intervalTitle.current = null;
    document.title = orginalTitle;
  };

  async function updateOrder(order) {
    if (
      order?.status == "canceled" &&
      order?.payment_method_code !== "cashondelivery"
    ) {
      wait(2000).then(() => {
        getOrderListRefund();
      });
    }

    if (order?.status == "canceled" || order?.status == "complete") {
      /* UPDATE REMOVE ORDER IN ORDER LIST UPDATE STATIUS == COMPLETE || CANCELED */
      let tempOrderList = await [...refOrderList.current];
      tempOrderList = tempOrderList?.filter?.(
        (obj) => obj?.id?.toString?.() !== order?.id?.toString?.()
      );
      setOrderList(tempOrderList);
      refOrderList.current = tempOrderList;
    } else {
      /* UPDATE STATUS ORDER of ORDERLIST */
      for (let i = 0; i < refOrderList.current?.length; i++) {
        if (refOrderList.current[i].id == order?.id) {
          let tempOrderList = await [...refOrderList.current];
          if (
            statusConvertData[order?.status] >=
            statusConvertData[tempOrderList[i].status]
          ) {
            tempOrderList[i].status = order?.status;
            refOrderList.current = tempOrderList;
            setOrderList(tempOrderList);
            return;
          }
        }
      }
    }
  }

  useEffect(() => {
    firebaseMessaging.onMessage((payload) => {
      if (payload?.notification?.title?.toString()?.includes("Đơn hàng mới")) {
        const audio = new Audio(sound);
        audio.play();
        wait(5000).then(() => {
          getOrderList();
        });
      } else {
        if (payload?.data?.order) {
          let order = payload?.data?.order;
          if (isJsonValid(payload?.data?.order)) {
            order = JSON.parse(payload?.data?.order);
          }
          wait(2000).then(() => {
            updateOrder(order);
          });
        } else {
          wait(5000).then(() => {
            getOrderList();
          });
        }
      }

      customNotification(
        "success",
        <NotificationComponent
          title={payload?.notification?.title}
          content={payload?.notification?.body}
        />,
        "notification-custom"
      );

      apiGetNotificationList({ type: "merchant" }).then((res) => {
        if (currentModalOrder?.current?.status === "pending") {
          if (
            orderList.findIndex(
              (order) =>
                order.order_number === currentModalOrder.current.order_number
            ) === -1
          ) {
            currentModalCancel.current();
          }
        }
      });

      set_reload(!reload);
    });

    return () => {
      clearInterval(loopPlayAudio.current);
      clearTimeout(playAudio.current);
    };
  }, []);
  const [isReloadInProgress, setIsReloadInProgress] = useState(false);
  const [storeInfo, setStoreInfo] = useState({});
  useEffect(() => {
    getStoreInfo().then((res) => {
      if (res?.data?.getMerchantInfo) {
        setStoreInfo(res?.data?.getMerchantInfo);
      }
    });
  }, []);
  const debouncedSetReload = () => {
    const currentDate = moment();
    const date_create_from = currentDate
      .subtract(1, "day")
      .startOf("day")
      .format("YYYY-MM-DD HH:mm:ss");
    const date_create_to = currentDate
      .add(2, "day")
      .endOf("day")
      .format("YYYY-MM-DD HH:mm:ss");
    let dataFilter = {
      date_create_from: date_create_from,
      date_create_to: date_create_to,
    };
    if (typeSelect.length > 0) {
      const list_type = typeSelect.map((e) => {
        return getValueOfTypeByLabel(e);
      });
      dataFilter = {
        ...dataFilter,
        type_of_food: list_type,
      };
    }
    apiGetListOrder(dataFilter, orderList.length > 0 ? true : false).then(
      (res) => {
        if (isUpdateLoading) return;
        if (!res.errors && res.data) {
          const quoteList = res?.data?.kitchenGetAllItems?.quote_items.map(
            (e) => {
              return {
                ...e,
                type: "QUOTE",
              };
            }
          );
          const orderList = res?.data?.kitchenGetAllItems?.order_items.map(
            (e) => {
              return {
                ...e,
                type: "ORDER",
              };
            }
          );
          const list = [...quoteList, ...orderList];
          list.sort((a, b) => {
            return new Date(a.created_at) - new Date(b.created_at);
          });
          console.log("list", list);
          setOrderList(list);
          refOrderList.current = list;
        }

        setIsReloadInProgress(false); // Đánh dấu là không còn reload nữa
        setIsLoadingApp(false);
      }
    );
    getListTypeOfDish({
      date_create_from: date_create_from,
      date_create_to: date_create_to,
    })
      .then((res) => {
        if (isUpdateLoading) return;
        setTypeList(res?.data?.getListTypeOfDish?.items || []);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const setReload = () => {
    if (isReloadInProgress) {
      return;
    }

    setIsReloadInProgress(true);
    debouncedSetReload();
  };
  const playSoundNotResponse = () => {
    const pendingOders = orderList.filter(
      (item) => item.status === "pending"
    ).length;
    if (loopPlayAudioNotResponse.current) return;
    if (pendingOders == 0) {
      const audio = new Audio(soundNotResponse);
      audio.play();
      loopPlayAudioNotResponse.current = setInterval(() => {
        const audio = new Audio(soundNotResponse);
        audio.play();
      }, 2000);
    }
  };

  const openModal = async (type, order) => {
    setOrderDetail({ status: type, ...order });
    setShowOrderDetail(true);
    return;
  };

  const [socket, setSocket] = React.useState(null);

  const callPushSocketIdToServer = (socketId) => {
    apiPushSocketIdToServer({ socketId });
  };

  const pushNotificationLocal = (order) => {
    customNotification(
      "success",
      <NotificationComponent title={"Merchant FNB"} content={order} />,
      "notification-custom"
    );
  };

  useEffect(() => {
    // Kết nối tới máy chủ
    const socketInstance = io("https://fnb-socket.test88.info");

    // Lưu đối tượng socket vào state
    setSocket(socketInstance);

    // Thực hiện các xử lý khác khi kết nối thành công
    socketInstance.on("connect", () => {
      const socketId = socketInstance.id; //
      callPushSocketIdToServer(socketId);
      console.log("Kết nối thành công đến máy chủ socket.");
    });
    socketInstance.on("client list", (msg) => {
      console.log("test", msg);
    });
    socketInstance.on("reconnect", (messages) => {});

    socketInstance.on("chat message", (msg, callback) => {
      pushNotificationLocal(msg?.message);
      setReload();
      return;
    });

    return () => {
      // Ngắt kết nối khi component unmount
      if (socketInstance) {
        socketInstance.disconnect();
        socketInstance.off("client list");
      }
    };
  }, []);

  const closeModalCancel = () => {
    setShowModalCancel(false);
  };

  const handleSetCooking = async (data, orderType = "QUOTE") => {
    setLoadingButton(true);
    const res = await apiCookingItem({ id: data, type: orderType });
    setLoadingButton(false);
    if (!res.errors && res.data) {
      setShowModalPending(false);
      setReload(!reload);
      return true;
    } else {
      info({
        icon: <></>,
        title: <span style={{ fontWeight: "bold" }}>Thất bại</span>,
        content: res?.errors[0]?.message,
      });
      return false;
    }
  };

  const handleSubmitCookingOrder = async (data, orderType = "QUOTE") => {
    setLoadingButton(true);
    const res = await apiSubmitCookingOrder({ id: data, type: orderType });
    setLoadingButton(false);
    if (!res.errors && res.data) {
      setShowModalRecieved(false);
      setReload(!reload);
      return true;
    } else {
      info({
        icon: <></>,
        title: <span style={{ fontWeight: "bold" }}>Thất bại</span>,
        content: res?.errors && res?.errors[0]?.message,
      });
      return false;
    }
  };

  const handleSubmitCompleteOrder = async (data, orderType = "QUOTE") => {
    setLoadingButton(true);
    const res = await apiSubmitCompleteOrder({ id: data, type: orderType });
    setLoadingButton(false);
    if (!res.errors && res.data) {
      setShowModalRecieved(false);
      setReload(!reload);
      return true;
    } else {
      info({
        icon: <></>,
        title: <span style={{ fontWeight: "bold" }}>Thất bại</span>,
        content: res?.errors[0]?.message,
      });
      return false;
    }
  };

  const handleSubmitBom = (dataOrder = dataOrderModal) => {
    setLoadingButton(true);
    apiBomOrder({
      id: dataOrder.id,
    }).then((res) => {
      setLoadingButton(false);
      if (!res.errors && res.data) {
        customNotification(
          "success",
          `${t("order")} ${dataOrder?.order_number} ${t("confirmBom")}`
        );
        setShowModalReadyShip(false);
        setShowModalShipping(false);
        closeModalCancel();
        setReload();
      }
    });
  };

  const [isShowModalDelivered, setIsShowModalDelivered] = useState(false);

  const [isShowModalChangeStaff, setIsShowModalChangeStaff] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const updateOrderNextStatus = async (orderId, nextStatus, orderType) => {
    const indexUpdate = orderList?.findIndex((or) => or?.id == orderId);

    if (indexUpdate == -1) return;

    let tempOrderList = [...orderList];
    tempOrderList[indexUpdate].status = nextStatus;
    setOrderList(tempOrderList);
    refOrderList.current = tempOrderList;
    if (nextStatus === "cooking") {
      setIsUpdateLoading(true);
      const status = await handleSetCooking(orderId, orderType);
      setIsLoadingApp(false);
      if (status) {
        setReload();
      }
      setIsUpdateLoading(false);
      return;
    }
    if (nextStatus === "ready") {
      setIsUpdateLoading(true);
      const status = await handleSubmitCookingOrder(orderId, orderType);
      setIsLoadingApp(false);
      if (status) {
        setReload();
      }
      setIsUpdateLoading(false);
      return;
    }
  };

  const getOrderListWithoutLoading = () => {
    if (
      !isShowModalCancel ||
      !isShowModalChangeStaff ||
      !isShowModalCooking ||
      !isShowModalCookingPickUp ||
      !isShowModalDelivered ||
      !isShowModalPending ||
      !isShowModalReadyShip ||
      !isShowModalRecieved ||
      !isShowModalRejectOrder ||
      !isShowModalShipping
    ) {
      setReload();
    }
  };

  React.useEffect(() => {
    reloadOrderRef.current = setInterval(
      () => {
        getOrderListWithoutLoading();
      },
      timeReloadOrder ? parseInt(timeReloadOrder) * 1000 : 30000
    );

    return () => {
      console.log("clear interval");
      clearInterval(reloadOrderRef.current);
    };
  }, [typeSelect]);

  const getOrderList = async () => {
    setIsLoadingApp(true);
    setReload();
  };

  const getOrderListRefund = () => {
    apiGetListOrderRefund().then((res) => {
      if (!res.errors && res.data) {
        setRefundOrderList(res.data.merchantOrderHistory?.orders || []);
      }
    });
  };
  let timeoutId;
  const handleDragEnd = async ({ draggableId, source, destination }) => {
    if (source.droppableId === "ready") return;
    if (source.droppableId === "cooking" && destination.droppableId === "sent")
      return;
    if (source.droppableId === "sent" && destination.droppableId === "ready")
      return;
    const orderId = source?.index;
    const dataId = draggableId.split("+");
    const id = dataId[0];
    const created_at = dataId[1];
    const type = dataId[2];
    console.log(id, created_at, type);
    const getDragItems = orderList?.filter(
      (or) =>
        or?.status == type && or?.created_at == created_at && or?.cart_id == id
    );
    console.log(getDragItems);
    const nextStatus = destination?.droppableId;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    getDragItems.forEach((or) => {
      updateOrderNextStatus(or?.id, nextStatus, or?.type);
    });
  };
  const onServed = (orderId, type) => {
    handleSubmitCompleteOrder(orderId, type);
    let tempOrderList = [...orderList];
    const newList = tempOrderList.filter((or) => or?.id != orderId);
    setOrderList(newList);
    refOrderList.current = newList;
  };
  useEffect(() => {
    bc.onmessage = (eventMessage) => {
      setTimeout(() => {
        getOrderList();
      }, 2000);
    };
  }, []);

  useEffect(() => {
    const visibilityChange = () => {
      if (document.hidden) {
      } else {
        stopIntervalTitle();
        getOrderList();
      }
    };
    document.addEventListener("visibilitychange", visibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", visibilityChange);
    };
  }, [typeSelect]);

  const renderHeaderColumnByStatus = (item, countOrderByStatus) => {
    return (
      <div className="columns-header">
        <div className="columns-header-left">
          {renderImageTitle(item?.status)}
          <span className="columns-header-title">{t(item?.title)}</span>
          <span className="columns-header-count">
            {`${countOrderByStatus}`}
          </span>
        </div>

        <div className="colums-header-right"></div>
      </div>
    );
  };
  const [highlightId, setHighLightId] = useState("");

  useEffect(() => {
    getOrderList();
  }, [typeSelect]);
  const handleCancelItem = async (data) => {
    const value = data.split("-");
    const res = await apiKitchenCancelItem({ id: value[1], type: value[0] });
    if (!res.errors && res.data) {
      setShowModalRecieved(false);
      setReload(!reload);
      return true;
    } else {
      info({
        icon: <></>,
        title: <span style={{ fontWeight: "bold" }}>Thất bại</span>,
        content: res.errors[0].message,
      });
      return false;
    }
  };
  const { isLightMode } = useContext(ThemeContext);
  function groupItemsByCartIdAndCreatedAt(items) {
    const groupedItems = [];

    // Tạo một map tạm để nhóm các items theo cartID và created_at
    const tempMap = {};

    items.forEach((item) => {
      const key = `${item.cart_id}_${item.created_at}`;

      // Nếu chưa có key này trong map, khởi tạo nó
      if (!tempMap[key]) {
        tempMap[key] = {
          cartID: item.cart_id,
          created_at: item.created_at,
          type: item.type,
          table_name: item.table_name,
          order_number: item?.order_number,
          items: [],
        };
      }

      // Thêm item vào array items của key này
      tempMap[key].items.push(item);
    });

    // Chuyển các phần tử trong map thành array kết quả
    for (const key in tempMap) {
      groupedItems.push(tempMap[key]);
    }

    return groupedItems;
  }
  return (
    <Container>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div
          className={isLightMode ? "home-page-light" : `home-page`}
          style={{ position: "relative" }}
        >
          {/* <Header reload={reload} refundOrderList={refundOrderList} /> */}
          {/* <div className="type-list">
            {typeList.map((item) => {
              return (
                <div
                  className={`type-item ${
                    typeSelect.includes(item?.dish_name)
                      ? "type-item-active"
                      : ""
                  }`}
                  key={item?.dish_name}
                  onClick={() => {
                    setTypeSelect((prevTypeSelect) => {
                      if (prevTypeSelect.includes(item?.dish_name)) {
                        return prevTypeSelect.filter(
                          (e) => e !== item?.dish_name
                        );
                      } else {
                        return [...prevTypeSelect, item?.dish_name];
                      }
                    });
                  }}
                >
                  <span>{item?.dish_name}</span>
                  {item?.count > 0 ? (
                    <span className="type-item-count">{item?.count}</span>
                  ) : (
                    <span>{item?.count}</span>
                  )}
                </div>
              );
            })}
          </div> */}
          <div style={{ display: "flex", justifyContent: "center", gap: 24 }}>
            <ButtonSelectMode isSelect={true} title={t("ByOrder")} />
            <ButtonSelectMode title={t("ByDish")} />
          </div>
          {isLoadingApp && (
            <div className="loading_container">
              <Spin />
            </div>
          )}
          {orderList && !isLoading ? (
            <div className="home-board">
              <div className="container-box">
                <div className="board-wrapper">
                  {STATUS_COLUMNS?.map((item, index) => {
                    const list_order = orderList
                      ?.map((order_item) => {
                        if (
                          order_item?.status?.toLowerCase?.() ===
                          item?.status?.toLowerCase?.()
                        ) {
                          return order_item;
                        }
                        return null;
                      })
                      .filter((item) => item);
                    let renderList = groupItemsByCartIdAndCreatedAt(list_order);
                    const countOrderByStatus = list_order?.length ?? 0;

                    return (
                      <Droppable droppableId={item?.status} key={index}>
                        {(provided, _snapshot) => (
                          <div
                            ref={provided.innerRef}
                            className="board-columns"
                            key={`${index}-${item.status}`}
                          >
                            {/******************** RENER TITLE HEADER STATUS *********************/}

                            {renderHeaderColumnByStatus(
                              item,
                              countOrderByStatus
                            )}

                            {/******************** RENER LIST ORDER BY COLUMN STATUS *********************/}
                            <div
                              className={
                                isLightMode
                                  ? "colums-wrapper-light"
                                  : "colums-wrapper"
                              }
                            >
                              {renderList?.map((order, i) => {
                                return (
                                  <OrderV2
                                    key={`${order?.id}-${i}-${item.status}`}
                                    handleSubmitBom={handleSubmitBom}
                                    order={order}
                                    id={i}
                                    playSound={playSoundNotResponse}
                                    saveOrderListNotResponse={
                                      saveOrderListNotResponse
                                    }
                                    orderListNotResponse={orderListNotResponse}
                                    playOrderNOtResponseAgain={
                                      playOrderNOtResponseAgain
                                    }
                                    turnOffAppSound={turnOffAppSound}
                                    updateOrderNextStatus={
                                      updateOrderNextStatus
                                    }
                                    itemStatus={item?.status}
                                    onServed={onServed}
                                    openModal={openModal}
                                  />
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </Droppable>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            <Row justify="center" style={{ marginTop: 200 }}>
              <Spin spinning />
            </Row>
          )}

          <OrderDetail
            isShowOrderDetail={isShowOrderDetail}
            onClose={() => setShowOrderDetail(false)}
            data={orderDetail}
            handleSetCooking={handleSetCooking}
            loading={loadingButton}
            handleSubmitCookingOrder={handleSubmitCookingOrder}
            handleSubmitCompleteOrder={handleSubmitCompleteOrder}
            handleCancelItem={handleCancelItem}
          />
        </div>
      </DragDropContext>
    </Container>
  );
};

export default HomePage;
