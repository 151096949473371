import { Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "../OrderDetail/index.scss";
import { isNaN } from "lodash";

export default function OrderDetailHistory({
  isShowOrderDetail = false,
  onClose = () => {},
  data = null,
  loading = false,
}) {
  const { t } = useTranslation();

  const onCloseModal = () => {
    onClose();
  };

  React.useEffect(() => {
    if (!loading) {
      onCloseModal();
    }
  }, [loading]);

  const convertMinutesToHoursAndMinutes = (minutes = 0) => {
    let hours = Math.floor(Math.abs(minutes) / 60);
    let remainingMinutes = Math.abs(minutes) % 60;
    let result = "";
    if (hours == 0 && minutes == 0) return `0 ${t("minute")}`;

    if (hours === 0) {
      result = String(remainingMinutes).padStart(2, "0") + ` ${t("minute")}`;
    } else {
      result =
        String(hours).padStart(2, "0") +
        ` ${t("hour")} ` +
        String(remainingMinutes).padStart(2, "0") +
        ` ${t("minute")}`;
    }

    return result;
  };

  const getTotalTime = () => {
    let totalMinutes = 0;
    for (let index = 0; index < data?.items?.length; index++) {
      const it = data?.items?.[index];
      const start = moment(it?.start, ["hh:m A"]);
      const done = moment(it?.done, ["hh:m A"]);
      const diff = done.diff(start, "minutes");
      totalMinutes += diff;
    }

    return isNaN(totalMinutes) ? 0 : totalMinutes;
  };

  const renderBottom = () => {
    const totalTimes = convertMinutesToHoursAndMinutes(getTotalTime());
    return (
      <div className="orderDetailHistory_bottom">
        <p>Total time</p>
        <p>{totalTimes}</p>
      </div>
    );
  };
  return (
    <Modal
      key="PopupOrderDetail"
      centered={true}
      destroyOnClose={true}
      className="modal-cooking popup-orderDetail"
      title={null}
      visible={isShowOrderDetail}
      width={900}
      onCancel={onCloseModal}
      footer={null}
    >
      <div className="orderDetail">
        <div className="orderDetail_row">
          <div>
            <h2>
              {t("order")} # {data?.order_number}
            </h2>
            <p>
              {t("receivedOrderAt")}{" "}
              {moment(data?.created_at, ["YYYY-MM-DD HH:mm:ss"]).format(
                "hh:mm A"
              )}
            </p>
            {/* api chưa trả table */}
            {data?.table && (
              <div className="orderDetail_row">
                <p>{t("Table")}</p>
                <p
                  style={{ fontWeight: "600", fontSize: 13, color: "#146C43" }}
                >
                  {data?.table}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="orderDetail_line" />
        <h4>{`(${t("requirePlasticCutlery")})`}</h4>
        <h4 style={{ fontWeight: 700, color: "#CC451B", fontSize: 14 }}>
          {t("note")}:
        </h4>
        <div className="title item_title">
          <div className="col-item">{t("Item")}</div>
          <div className="col-w-100">{t("cook")}</div>
          <div className="col-w-100">{t("start")}</div>
          <div className="col-w-100">{t("done")}</div>
        </div>
        {data?.items?.map?.((it) => (
          <div className="item" key={`${it?.name} ${Math.random()}`}>
            <div className="col-item">{it?.name}</div>
            <div className="col-w-100">{/* api chưa trả  */}</div>
            <div className="col-w-100">{it?.start}</div>
            <div className="col-w-100">{it?.done}</div>
          </div>
        ))}
        <div className="line" />
        {renderBottom()}
      </div>
    </Modal>
  );
}
