import React from "react";
import potIcon from "./pot.png";
import nextIcon from "./next.png";
import foodIcon from "./food.png";
import nextWhiteIcon from "./next-white.png";
export default function ButtonChangeStatusItem({ type, onClick, onServed }) {
  return type === "sent" ? (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        background: "#FF9D00",
        borderRadius: 4,
        paddingBlock: 6,
        marginLeft: 20,
        paddingInline: 12,
      }}
      onClick={onClick}
    >
      <img src={potIcon} alt="potIcon" style={{ width: 17, height: 17 }} />
      <img src={nextIcon} alt="nextIcon" style={{ width: 20, height: 20 }} />
    </div>
  ) : type === "cooking" ? (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        background: "#198754",
        borderRadius: 4,
        paddingBlock: 6,
        marginLeft: 20,
        paddingInline: 12,
      }}
      onClick={onClick}
    >
      <img src={foodIcon} alt="potIcon" style={{ width: 17, height: 17 }} />
      <img
        src={nextWhiteIcon}
        alt="nextIcon"
        style={{ width: 20, height: 20 }}
      />
    </div>
  ) : (
    <div
    // style={{
    //   display: "flex",
    //   flexDirection: "row",
    //   justifyContent: "center",
    //   alignItems: "center",
    //   cursor: "pointer",
    //   background: "#198754",
    //   borderRadius: 4,
    //   paddingBlock: 6,
    //   marginLeft: 20,
    //   paddingInline: 12,
    // }}
    // onClick={onServed}
    >
      {/* <img src={foodIcon} alt="potIcon" style={{ width: 17, height: 17 }} /> */}
    </div>
  );
}
