import React, { useContext } from "react";
import logoImage from "../assets/logo/logo.png";
import menuIcon from "../assets/headerContainer/menu.png";
import bellIcon from "../assets/headerContainer/bell.png";
import questionIcon from "../assets/headerContainer/question.png";
import { ThemeContext } from "handlers/ThemeContext";
import Notify from "../components/Notify";
import { getLocalStorage } from "utils/CommomFunction";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
export default function Container({ children }) {
  const { isLightMode, toggleMode } = useContext(ThemeContext);
  const isLogin = () => {
    return getLocalStorage("access_token");
  };
  const { t, i18n } = useTranslation();
  const setLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("i18nextLng", lang);
  };
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          paddingInline: 20,
          paddingTop: 20,
          background: isLightMode ? "#F3F3F3" : "#0B111D ",
          justifyContent: "space-between",
        }}
      >
        <img
          src={logoImage}
          style={{
            height: 44,
            width: "auto",
            resizeMode: "contain",
          }}
          alt="React Starter"
        />
        <div style={{ display: "flex", gap: 20 }}>
          <Select
            defaultValue={localStorage.getItem("i18nextLng") || "en"}
            onChange={setLanguage}
            bordered={false}
            style={{
              height: 40,
              border: "1px solid",
              borderRadius: 5,
              borderColor: "#FF9D00",
              color: "#FF9D00",
              fontSize: 18,
              fontWeight: "600",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 25,
              marginRight: 25,
            }}
            options={[
              { value: "vi", label: "VN" },
              { value: "en", label: "EN" },
            ]}
          />
          <img
            src={menuIcon}
            style={{
              height: 36,
              width: 36,
              resizeMode: "contain",
              cursor: "pointer",
            }}
            onClick={toggleMode}
          />

          <img
            src={questionIcon}
            style={{
              height: 36,
              width: 36,
              resizeMode: "contain",
              cursor: "pointer",
            }}
          />
          {isLogin() && <Notify />}
        </div>
      </div>
      {children}
    </div>
  );
}
