import React from "react";
import LoginForm from "./Form";
import { Modal } from "antd";
import "./index.scss";

const { info } = Modal;

const PageLogin = (props) => {
  const checkNotificationPermission = () => {
    if (Notification.permission !== "granted") {
      Notification.requestPermission().then(function (permission) {
        if (
          permission &&
          typeof permission == "string" &&
          permission == "denied"
        ) {
          info({
            icon: <></>,
            title: (
              <span style={{ fontWeight: "bold", fontSize: 24, color: "red" }}>
                Yêu cầu bật thông báo !!!
              </span>
            ),
            content: (
              <p style={{ fontWeight: "500", fontSize: 16 }}>
                Vui lòng bật thông báo browser để đăng nhập
              </p>
            ),
            onOk: () => {},
          });
        }
      });
    }
  };

  React.useEffect(() => {
    checkNotificationPermission();
  }, []);

  return (
    <LoginForm checkNotificationPermission={checkNotificationPermission} />
  );
};

export default PageLogin;
