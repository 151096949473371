export function getValueOfTypeByLabel(label) {
  const data = [
    { label: "Boiling", value: 1 },
    { label: "Steaming", value: 2 },
    { label: "Roasting", value: 3 },
    { label: "Baking", value: 4 },
    { label: "Frying", value: 5 },
    { label: "Stir Frying", value: 6 },
    { label: "Wine & Beer", value: 7 },
    { label: "Drinks", value: 8 },
    { label: "Desserts", value: 9 },
  ];

  const item = data.find((item) => item.label === label);
  return item ? item.value : null;
}
