import React, { useEffect, useState } from "react";
import { apiGetDetailOrder, apiGetOrderHistory } from "apis/Order";
import { useHistory } from "react-router-dom";
import { Table, Tag, Pagination, Spin } from "antd";
import { CustomInput } from "components";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import ic_back from "assets/ic_back.png";
import ic_history from "assets/ic_history.png";
import moment from "moment";
import OrderDetailHistory from "components/OrderDetailHistory";
import Header from "components/Notify";
import "./index.scss";

const ROW_PER_PAGE = 10;

const OrderHistory = () => {
  const [orderList, setOrderList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const [isShowOrderDetail, setShowOrderDetail] = useState(false);
  const [orderDetail, setOrderDetail] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [valueSearch, setValueSearch] = useState("");

  const windowHeight = window.innerHeight;

  const renderStatus = (record) => {
    let color = "#B02A37",
      content = "ĐÃ HỦY",
      padding = "5px 27px";
    switch (record.status) {
      case "complete":
        color = "#146C43";
        content = "HOÀN THÀNH";
        padding = "5px 10px";
        break;
    }
    return (
      <Tag
        color={color}
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={{
          borderRadius: "5px",
          fontWeight: "600",
          fontFamily: "Montserrat sans-serif",
          width: "96px",
          height: "24px",
          fontSize: "12px",
        }}
      >
        {content}
      </Tag>
    );
  };

  const columns = [
    {
      title: t("orderId"),
      dataIndex: "order_number",
      width: 100,
      render: (order_number) => <div>#{order_number}</div>,
    },
    {
      title: t("orderDate"),
      dataIndex: "created_at",
      align: "center",
      width: 150,
      render: (time) => (
        <div className="order-price">{moment(time).format("DD/MM/YYYY")}</div>
      ),
    },
    {
      title: t("orderForm"),
      dataIndex: "shipping_method",
      align: "center",
      width: 200,
      render: (method) => (
        <div className="order-price" style={{ textAlign: "center" }}>
          {method
            ?.toString?.()
            .replace?.("Nhận tại cửa hàng", "Hẹn lấy tại cửa hàng")}
        </div>
      ),
    },
    {
      title: t("cookingTime"),
      dataIndex: "shipping_method",
      align: "center",
      width: 200,
      render: (method) => null, // api chưa trả về
    },
    {
      title: t("status"),
      dataIndex: "status",
      align: "center",
      width: 150,
      render: (_, record) => renderStatus(record),
    },
    {
      title: "",
      dataIndex: "action",
      align: "right",
      width: 50,
      render: (_) => (
        <div key={1} className="view-more">
          Xem
        </div>
      ),
    },
  ];

  const fetchOrderDetail = (id) => {
    setIsLoading(true);
    apiGetDetailOrder({ id })
      .then((res) => {
        if (!res.errors && res.data) {
          setOrderDetail(res.data.orderDetail);
          setShowOrderDetail(true);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const getOrderHistory = (increment_id, page) => {
    setIsLoading(true);
    apiGetOrderHistory({
      currentPage: page,
      pageSize: ROW_PER_PAGE,
      ...(increment_id && { increment_id }),
    }).then((res) => {
      setIsLoading(false);
      if (!res.errors && res.data) {
        setOrderList(res.data.merchantOrderHistory?.orders);
        setTotalPages(
          res.data?.merchantOrderHistory?.page_info?.total_pages ?? 1
        );
      }
    });
  };

  useEffect(() => {
    getOrderHistory(null, currentPage);
  }, [currentPage]);

  useEffect(() => {
    getOrderHistory(valueSearch, 1);
    if (isEmpty(valueSearch)) {
      setCurrentPage(1);
    }
  }, [valueSearch]);

  const handleSearch = (e) => {
    const value = e.target.value;
    setValueSearch(value);
  };

  return (
    <>
      <OrderDetailHistory
        isShowOrderDetail={isShowOrderDetail}
        onClose={() => setShowOrderDetail(false)}
        data={orderDetail}
        loading={false}
      />
      {isLoading && (
        <div className="loading_container">
          <Spin />
        </div>
      )}
      <Header />
      <div className="container-box body_history">
        <div className="header-bottom">
          <h3 className="header-bottom-left">
            <img
              src={ic_history}
              alt="icon"
              style={{ width: 36, height: 36, marginRight: 12 }}
            />
            {t("orderHistory")}
          </h3>
          <div className="header-bottom-right">
            <div className="search-bar">
              <CustomInput
                placeholder={t("orderId")}
                value={valueSearch}
                onChange={handleSearch}
              />
            </div>

            <button className="back-btn" onClick={() => history.push("/home")}>
              {" "}
              <img
                src={ic_back}
                style={{ marginRight: "10px", width: 24, height: 24 }}
              />
              {t("goback")}
            </button>
          </div>
        </div>

        <div style={{ flex: 1 }}>
          <Table
            rowKey="order_number"
            columns={columns}
            loading={isLoading}
            onRow={(record, index) => ({
              onClick: () => {
                fetchOrderDetail(record.id);
              },
            })}
            dataSource={orderList}
            className="table-refund"
            pagination={false}
            scroll={{ y: windowHeight - 300 }}
          />
          <Pagination
            current={currentPage}
            total={(ROW_PER_PAGE * totalPages) / 2}
            pageSize={5}
            onChange={(page) => {
              setCurrentPage(page);
            }}
            style={{ marginTop: "20px", textAlign: "right" }}
          />
        </div>
      </div>
    </>
  );
};

export default OrderHistory;
