import React, { useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import { getLocalStorage } from "src/utils/CommomFunction";
import { HomePage, PageLogin } from "./pages";
import NotFound from "./pages/404/NotFound";
import Maintenance from "./pages/404/Maintenance";
import ShipperPage from "pages/Shipper";
import OrderHistory from "pages/OrderHistory";
import { useTranslation } from "react-i18next";
export const MyRoutes = () => {
  const isLogin = () => {
    return getLocalStorage("access_token");
  };
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocalStorage("i18nextLng") || "en");
  }, []);
  return (
    <div>
      <Switch>
        <Route exact path="/login">
          {isLogin() ? <Redirect to="/home" /> : <PageLogin />}
        </Route>
        <Route exact path="/home" component={HomePage} />
        <Route exact path="/history">
          {isLogin() ? <OrderHistory /> : <PageLogin />}
        </Route>
        <Route exact path="/shipper">
          {isLogin() ? <ShipperPage /> : <PageLogin />}
        </Route>
        <Route exact path="/">
          {isLogin() ? <Redirect to="/home" /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/notfound" component={NotFound}></Route>
        <Route exact path="/maintenance" component={Maintenance}></Route>
        <Redirect from="*" to="/" />
      </Switch>
    </div>
  );
};
