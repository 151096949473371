import { callApi } from "..";

export const apiChangeStoreStatus = (data) =>
  callApi(
    `mutation (
    $status: Int!
    $weekday: String!
    $weekend: String!
  ) {
    storeStatus(
        status: $status
        weekday: $weekday
        weekend: $weekend
    ) {
        result
    }
}`,
    data
  );
export const apiAttendance = () =>
  callApi(
    `
  mutation{
    dailyAttendance {
      result
    }
  }
  `,
    null
  );

export const getStoreInfo = () =>
  callApi(
    `{
  getMerchantInfo {
      lastname
      firstname
      restaurant_address
      restaurant_name
      restaurant_id
  }
}`,
    null
  );
