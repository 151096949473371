import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { MyRoutes } from "./routes";
import {
  firebaseMessaging,
  requestFirebaseNotificationPermission,
} from "./firebaseInit";
import NetworkProvider from "./components/NetworkProvider";
import "./ReactotronConfig";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { ThemeProvider } from "handlers/ThemeContext";
const App = (props) => {
  useEffect(() => {
    if (firebaseMessaging) {
      requestFirebaseNotificationPermission()
        .then((firebaseToken) => {
          localStorage.setItem("fcmToken", firebaseToken);
        })
        .catch((err) => {
          return err;
        });
    }
    firebaseMessaging
      .getToken({
        vapidKey:
          "BHvwoHJ48Nr-9gLJqGbrFyve1HSwSxFkSaBIYMeq9T1mQ-8B_H-jDpQp0TvXClPHFrMkkejlDOhkOwrbIFTEM2A",
      })
      .then((firebaseToken) => {
        localStorage.setItem("fcmToken", firebaseToken);
      });
  }, []);
  return (
    <NetworkProvider>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <ThemeProvider>
            <MyRoutes />
          </ThemeProvider>
        </I18nextProvider>
      </BrowserRouter>
    </NetworkProvider>
  );
};

export default App;
