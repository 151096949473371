export const enTranslation = {
  loginTitle: "Login To System",
  password: "Password *",
  storeId: "Store Id*",
  loginError: "Store Id or Password is not correct!",
  loginText: "LOGIN",
  pending: "New",
  received: "Received",
  cooking: "Cooking",
  ready_to_ship: "Ready to delivery",
  shipping: "Shipping",
  name: "DISH",
  price: "PRICE",
  count: "QTY",
  total: "TOTAL",
  orderHistory: "ORDER HISTORY",
  logout: "LOGOUT",
  logoutConfirmText: "Are you sure you want to logout?",
  confirmText: "CONFIRM",
  version: "Version",
  cancelText: "Cancel",
  movedFrom: "Ported from",
  orderNumber: "Order Number",
  receivedAt: "Received At",
  item: "item",
  chanelBooking: "Order channel",
  Delivered: "Delivered on time",
  storePickup: "Appointment at the store",
  shipperAlertChange: "Order has been changed Shipper",
  changeShipperTitle: "Change delivery staff",
  pleaseSelectShipper: "Please select delivery staff",
  shipper2order: "Shipper can only deliver 2 orders at a time",
  totalOrderShipping: "Total orders being shipped",
  confirmBomOrder: "CONFIRM BOM ORDER",
  receivedOrder: "RECEIVE ORDER",
  reasonCancelOrder: "Please select the reason for canceling the order",
  success: "Success",
  refundSucess: "Refund successful",
  order: "Order",
  canceled: "has been canceled",
  refuseOrder: "Refuse order",
  confirnRefuse: "CONFIRM REFUSE TO RECEIVE ORDER",
  goback: "Back",
  orderIsNotFeasible: "Order is not feasible",
  otherReason: "Other reasons",
  pleaseEnterReason: "Please enter the reason",
  alertNotifyPermission: "Request to turn on notifications!!!",
  alertNotifyPermission2:
    "Please enable browser notifications to receive alerts when orders arrive.",
  newOrder: "New order",
  failed: "Failed",
  notifyNewOrder: "You have received a new order",
  orderUpdateStatus: "The order has just been updated to",
  confirmBom: "has been confirmed BOM",
  confirmFinishOrder: "CONFIRM COMPLETION OF ORDER",
  confirmRefundMoney: "Confirm refund for order",
  revenue: "STORE REVENUE",
  date: "DATE",
  orderMethod: "Order method",
  delivery: "Home delivery",
  paymentMethod: "Payment method",
  receivedExp: "Expected delivery",
  deliveredAt: "Delivered at",
  deliveryTo: "Delivery to",
  plasticEating: "(Plastic eating utensils)",
  itemDetail: "ITEM DETAIL",
  note: "NOTE",
  provisional: "Provisional",
  discount: "Discount",
  shippingFee: "Shipping fee",
  totalMoney: "Total",
  moneyCollected: "Customer collected",
  reject: "Reject",
  readyToDelilver: "Ready To Deliver",
  completeOrder: "Complete Order",
  cancel: "Cancel",
  cooking: "Cooking",
  order: "Order",
  receivedOrderAt: "Received order at",
  reportIssue: "Report issue",
  note: "Note",
  item: "ITEM",
  orderId: "ORDER ID",
  orderDate: "ORDER DATE",
  orderForm: "ORDER FORM",
  cookingTime: "COOKING TIME",
  status: "STATUS",
  orderHistory: "ORDER HISTORY",
  search: "Search",
  back: "BACK",
  cook: "Cook",
  start: "start",
  done: "Done",
  requirePlasticCutlery: "Require plastic cutlery",
  minute: "minute",
  hour: "hour",
  ByOrder: "By Order",
  ByDish: "By Dish",
  Ready: "Ready",
};
