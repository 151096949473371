import React from "react";
import { Modal, Button } from "antd";
import "./index.scss";
import { CloseCircleFilled } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { apiLogout } from "../../apis/Auth";
import { useTranslation } from "react-i18next";

export const ConfirmLogoutModal = (props) => {
  const { isShowConfirmLogout, closeModalConfirmLogout } = props;
  const history = useHistory();
  const { t } = useTranslation();

  const logOut = () => {
    localStorage.clear();
    history.push("/login");
  };

  const handleLogout = async () => {
    try {
      apiLogout(data).then(() => {
        logOut();
      });
    } catch (error) {
      logOut();
    }
  };

  const footer = (
    <div className="modal-button-container" style={{ textAlign: "center" }}>
      <div className="modal-button-container"></div>
      <Button className="modal-button-confirm" onClick={handleLogout}>
        <div className="modal-text-confirm">{t("confirmText")}</div>
      </Button>
    </div>
  );
  const closeIcon = (
    <span>
      <CloseCircleFilled
        style={{
          color: "#e31837",
          marginLeft: "70px",
          fontSize: 46,
          backgroundColor: "#ffffff",
          borderRadius: 50,
        }}
      />
    </span>
  );
  return (
    <Modal
      centered={true}
      destroyOnClose={true}
      footer={footer}
      className="modal-container modal-logout"
      title={<></>}
      visible={isShowConfirmLogout}
      onCancel={closeModalConfirmLogout}
      closeIcon={closeIcon}
    >
      <div
        style={{
          fontWeight: "bold",
          fontSize: 23,
          textAlign: "center",
          color: "#fff",
        }}
      >
        {t("logoutConfirmText")}
      </div>
    </Modal>
  );
};
