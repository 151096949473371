import React from "react";
import { Input } from "antd";
import ic_search from "assets/ic_search.png";
import "./index.scss";

const CustomInput = ({ value = "", onChange = () => {}, ...props }) => {
  return (
    <div className="container_customInput">
        <img src={ic_search} alt="search..." style={{ width: 24, height: 24 }} />
      <Input
        className="customInput"
        value={value}
        onChange={onChange}
        {...props}
      />
    </div>
  );
};

export { CustomInput };
