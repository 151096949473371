export const Colors = [
  { backgroundColor: "#333333", textColor: "#FFFFFF" },
  { backgroundColor: "#666666", textColor: "#FFFFFF" },
  { backgroundColor: "#999999", textColor: "#FFFFFF" },
  { backgroundColor: "#CCCCCC", textColor: "#000000" },
  { backgroundColor: "#001F3F", textColor: "#FFFFFF" },
  { backgroundColor: "#00449E", textColor: "#FFFFFF" },
  { backgroundColor: "#357EDD", textColor: "#FFFFFF" },
  { backgroundColor: "#7FDBFF", textColor: "#000000" },
  { backgroundColor: "#2ECC40", textColor: "#000000" },
  { backgroundColor: "#FFDC00", textColor: "#000000" },
  { backgroundColor: "#FF851B", textColor: "#000000" },
  { backgroundColor: "#B10DC9", textColor: "#FFFFFF" },
  { backgroundColor: "#9ACD32", textColor: "#000000" },
  { backgroundColor: "#FF4136", textColor: "#FFFFFF" },
  { backgroundColor: "#FF69B4", textColor: "#000000" },
  { backgroundColor: "#7FDBFF", textColor: "#000000" },
  { backgroundColor: "#FFD700", textColor: "#000000" },
  { backgroundColor: "#FFA500", textColor: "#000000" },
  { backgroundColor: "#8B4513", textColor: "#FFFFFF" },
  { backgroundColor: "#A9A9A9", textColor: "#000000" },
  { backgroundColor: "#FF1493", textColor: "#FFFFFF" },
  { backgroundColor: "#FF0000", textColor: "#FFFFFF" },
  { backgroundColor: "#008000", textColor: "#FFFFFF" },
  { backgroundColor: "#008080", textColor: "#FFFFFF" },
  { backgroundColor: "#00FF00", textColor: "#000000" },
  { backgroundColor: "#4682B4", textColor: "#FFFFFF" },
  { backgroundColor: "#0000FF", textColor: "#FFFFFF" },
  { backgroundColor: "#40E0D0", textColor: "#000000" },
  { backgroundColor: "#87CEEB", textColor: "#000000" },
  { backgroundColor: "#800080", textColor: "#FFFFFF" },
  { backgroundColor: "#4B0082", textColor: "#FFFFFF" },
  { backgroundColor: "#D8BFD8", textColor: "#000000" },
  { backgroundColor: "#C71585", textColor: "#FFFFFF" },
  { backgroundColor: "#FFB6C1", textColor: "#000000" },
  { backgroundColor: "#FF4500", textColor: "#FFFFFF" },
  { backgroundColor: "#FF6347", textColor: "#FFFFFF" },
  { backgroundColor: "#FF7F50", textColor: "#FFFFFF" },
  { backgroundColor: "#FF8C00", textColor: "#FFFFFF" },
  { backgroundColor: "#FFA07A", textColor: "#000000" },
  { backgroundColor: "#FFDAB9", textColor: "#000000" },
];
